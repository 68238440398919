<template>
    <div class="orders">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h4 class="box-title">Notificaciones </h4>
            </div>
            <div >
              <div class="table-stats order-table ov-h">
                <table class="table ">
                  <thead>
                    <tr>
                      <th class="serial">#</th>
                      <th >Título</th>
                      <th>Mensaje</th>
                      <th class="serial">Fecha </th>
                      <th class="serial">Fecha leído</th>
                      <th class="serial">Leído?</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr class="on-over"
                     v-for="(item, index) in list_data"
                     :key="index" @click="detalle_tarea(item, index)">
                      <td class="serial">{{index + 1}}</td>
                      <td>{{item.title}}</td>
                      <td>  <span class="name">{{item.message}}</span> </td>
                      <td class="serial">
                        <span class="product">{{item.created_at|to_date_time}}</span> </td>
                      <td class="serial">
                        <span v-if="item.read_date">
                        {{item.read_date|to_date_time}}</span>
                        <span v-else>-----</span>
                      </td>
                      <td class="serial">
                        <span v-if="item.read" class="badge badge-complete">
                          {{item.read|status_to_text}}</span>
                        <span v-else class="badge badge-pending">
                          {{item.read|status_to_text}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> <!-- /.table-stats -->
            </div>
          </div> <!-- /.card -->
        </div>  <!-- /.col-lg-8 -->
      </div>
    </div>
</template>
<script>
import moment from 'moment';

/* eslint-disable */
export default {
  name: 'Notifications',
  created(){
    this.course_id =  this.$route.params.course_id
    this.list();
  },
  data() {
    return {
      list_data: [],
      url: 'notifications/',
      show_list: true,
      current_task: [],
      course_id: null,
      message: '',
      sending_request: false,
      base_url: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    list() {
      const payload = {};
      
      payload.limit = 25
      
      const self = this;
      this.sendRequest(payload).then((data) => {
        self.list_data = data.data;            
      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible autenticar.', 'error');
      });
      return null;
    },
    async putRequest(payload) {
      const data = await this.$http.put(this.url+`${payload.id}/`, payload);
      return data;
    },
    async retrieveRequest(payload) {
      const data = await this.$http.get(this.url+`${payload.id}/`,{params:  payload});
      return data;
    },
    async sendRequest(payload) {
      // const result = await this.$http.get(this.url, payload);
      const result = await this.$http.get(this.url,{params:  payload});
      return result;
    },
  },
  filters: {
    status_to_text(value) {
      if (value) return 'Sí';
      return 'No';
    },
    to_date(value) {
      if (!value ) return '-----'      
      return moment(value, 'YYYY-MM-DD', 'es').format('ll')
    },
    to_date_time(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD HH:mm:ss', 'es').format('lll')
    },
  },
};

</script>
<style lang="css" scoped>
  .on-over {
    cursor: pointer;
  }
  .fa-refresh {
      -webkit-animation: infinite-spinning 5s normal linear infinite;
      animation: infinite-spinning 5s normal linear infinite;
  }

  @keyframes infinite-spinning {
    0% {
       -webkit-transform: rotate3d(0, 0, 1, 0deg);
       transform: rotate3d(0, 0, 1, 0deg);
     }
     25% {
       -webkit-transform: rotate3d(0, 0, 1, 90deg);
       transform: rotate3d(0, 0, 1, 90deg);
     }
     50% {
       -webkit-transform: rotate3d(0, 0, 1, 180deg);
       transform: rotate3d(0, 0, 1, 180deg);
     }
     75% {
       -webkit-transform: rotate3d(0, 0, 1, 270deg);
       transform: rotate3d(0, 0, 1, 270deg);
     }
     100% {
       -webkit-transform: rotate3d(0, 0, 1, 360deg);
       transform: rotate3d(0, 0, 1, 360deg);
     }
  }
</style>
